import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeroH1, HeroH2 } from '../components/Typography';
import Page from '../components/layout/Page';
import SparkForm from '../components/SparkForm';
import { SpacedSection } from '../components/layout/Sections';
import { ReactComponent as EnquireMap } from '../assets/EnquireMap.svg';

const Enquire = styled.section`
  background-color: #C5B9AC;
  color: white;
  padding-bottom: 116px;


  h5 {
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    font-weight: 500;
  }

  h5 {
    letter-spacing: 0.72px;
  }

`

const Headline = styled.section`
  padding: 160px 0 80px;

  h2 {
    padding: 0 50px;
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    padding: 221px 0 106px;
  }

`

const Main = styled(SpacedSection)`
  display: flex;
  flex-direction: column;
  color: white;
  margin: 0;

  h3 {
    margin-bottom: 2em;
  }

  .beta {
    margin-top: 80px;
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    margin: 0;
    flex-direction: row-reverse;
    // justify-content: center;

    .beta {
      margin-top: 0;
    }
  }

`

const ContactInfo = styled.div`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.82px;
  margin-top: 35px;
  text-align: center;

  @media (min-width: ${props => props.theme.bp.large}) {
    font-size: 13px;
    margin-top: 0;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 1em;
  }

  a {
    text-decoration: none;
    &.map {
      text-decoration: underline;
    }
  }

  svg {
    margin-top: 45px;
  }
`


export default () => {
  const { t } = useTranslation();


  return <Page title="Enquire" header={{ enquire: false }}>
    <Enquire>
      <Headline>
        <HeroH1>{ t('At Sea in the Heart of the City') }</HeroH1>
        <HeroH2>{ t('Ninety-two Waterfront Homes in False Creek') }</HeroH2>
      </Headline>

      <Main first={278} second={768.5} gutterSecond={92}>
        <div className="alpha">
          <ContactInfo>
            <HeroH2 as="h3">{ t('Now Showing by Private Appointment') }</HeroH2>
            <div>{ t('If you have any questions, please contact us.') }</div>
            <a href="tel:6046818282">604.681.8282</a>
            <br/>
            <a href="mailto:Tesoro@ConcertProperties.com">Tesoro@ConcertProperties.com</a>
            <br/>
            <br/>
            <span>{ t('Suite 1302 – 1601 Quebec Street') }<br/>
            { t('Vancouver, BC V6A 0J9') }</span>
            {/* <a className="map" href="https://www.google.com/maps/place/Navio+North+(Navio+By+The+Creek)/@49.2710303,-123.1035245,15z/data=!4m5!3m4!1s0x0:0x488ee12c94a8dbea!8m2!3d49.2710303!4d-123.1035245" target="_blank" rel="noopener noreferrer">1202-1678 Pullman Porter Street</a><br/> */}
            {/* <span>Vancouver BC, V6A 0H4</span> */}
            {/* <EnquireMap /> */}
          </ContactInfo>
        </div>
        <div className="beta">
          <HeroH2 as="h3">{ t('Register for information and updates about TESORO') }</HeroH2>
          <SparkForm className="sparkForm" />
        </div>
      </Main>

    </Enquire>



  </Page>
}